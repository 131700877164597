const DELAY = 3

export const TimeoutMixinFactory = (delay = DELAY) => {
  return {
    data() {
      return {
        delay,
        intervalID: null,
        countdown: delay
      }
    },
    methods: {
      runTimeout(fn) {
        this.intervalID = setInterval(() => {
          this.countdown--
          if (this.countdown === 0) {
            this._clear()
            fn.call(this)
          }
        }, 1000)
      },
      stopTimeout() {
        this._clear()
      },
      _clear() {
        clearTimeout(this.intervalID)
        this.intervalID = null
        this.countdown = delay
      }
    }
  }
}
